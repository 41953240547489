const COURSE_CATEGORY_COLUMNS = `
  name
  id
  description
  visible
  created_at
  updated_at
  slug
  image_url
  moodle_category_id
`

const COURSE_PARTNER_COLUMNS = `
  id
  course_id
  partner_id
  course {
    id
    full_name
  }
  partner {
    id
    name
    logo
  }
`

const COURSE_COLUMNS = `
  id
  full_name
  description
  start_date
  end_date
  moodle_config_id
  moodle_course_url
  course_category_id
  publish
  is_moodle_course
  created_at
  updated_at
  identifier
  cost
  nsqf_level
  discount
  multilang
  image_url
  short_name
  moodle_course_id
  duration
  is_live_course
  video_url
  certificate_image_url
  
  course_category {
    ${COURSE_CATEGORY_COLUMNS}
  }
  course_partners: certification_partners {
    ${COURSE_PARTNER_COLUMNS}
  }
`

const BATCH_COLUMNS = `
  id
  from_time
  to_time
  batch_name
  course_id
  instructor_id
  slots_days
  platform
  min_learners
  max_learners
  partner_id
  meeting_link
  repeat_end_time
  created_at
  updated_at
  partner_project
  project_id
  is_demo
  slot_end_time
  slot_start_time
  from_date
  to_date
  type
  location
`

const BATCH_SLOT_COLUMNS = `
  id
  batch_id
  platform
  slots_days
  instructor_id
  meeting_link
  event_id
  updated_at
  created_at
  slot_date
  endto_date
  enable_slots
  type
  location
`

const FETCH_ALL_COURSES_QUERY = `
    query {
      courses_course (where: {
        publish: { _eq: true }
      }) {
        ${COURSE_COLUMNS}
      }
    }
  `

const FETCH_ALL_PARTNER_COURSES_QUERY = `
  query ($partner_id: bigint){
  courses_partner_revenue_split_directives(
    where: { partner_id: { _eq: $partner_id}
    					course: {
                publish: { _eq: true}
              }
    }
  ){
    course{
      ${COURSE_COLUMNS}
    }
  }
}
`

const GET_CERTIFICATION_LOGO = `
  query getPartnerLogo($partner_id: bigint = 2) {
    courses_partner(where: {id: {_eq: $partner_id}}) {
      logo
      contact_person_email
      contact_person_mobile_number
      contact_person_name
      name
    }
  }
`

const GET_ALL_COURSES_ENROLLED_USERS = `
  query getAllCoursesEnrolledUsers {
    courses_moodle_courses__user_stats {
      moodle_course_id
      total_users
    }
  }
`

const GET_COURSE_ENROLLED_USERS = `
  query getCourseEnrolledUsers ($courseId: Int, $courseDiscontinuedFromMoodleAt: timestamptz) {
    courses_user_course_enrolment_aggregate (
      where: {
        course_id: { _eq: $courseId }
        created_at: { _gt: $courseDiscontinuedFromMoodleAt }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

const GET_MOODLE_COURSE_ENROLLED_USERS = `
  query getCourseEnrolledUsers ($moodle_course_id: String) {
    courses_moodle_courses__user_stats (where: {
      moodle_course_id: { _eq: $moodle_course_id }
    }) {
      total_users
    }
  }
`

const GET_DEMO_BATCHES = `
  query getDemoBatchesByCourseId {
    courses_course_batches (
      where: {
        is_demo: { _eq: true }
      }
    ) {
      max_learners
      is_demo
      course_id
      batch_slots (
        where: {
          slot_date: { _gt: now }
        }
      ) {
        ${BATCH_SLOT_COLUMNS}
        slot_enrolled_users_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`
const GET_COURSE_MODULES = `
  query getcoursesection($courseId: Int) {
    courses_course_section(
      where: { course_id: { _eq: $courseId }, visible: { _eq: true } }
      order_by: { order: asc }
    ) {
      name
      id
      course_id
      coursesec(where: { visible: { _eq: true } }, order_by: { order: asc }) {
        name
        description
        mod_id
        id
        publish_url
        section_id
        completion_percentage
        completion_criteria
        course_id
      }
    }
  }
`

const GET_DEMO_BATCHES_BY_COURSE_ID = `
  query getDemoBatchesByCourseId ($courseId: Int) {
    courses_course_batches (
      where: {
        course_id: { _eq: $courseId }
        is_demo: { _eq: true }
      }
    ) {
      max_learners
      is_demo
      course_id
      batch_slots (
        where: {
          slot_date: { _gt: now }
        }
      ) {
        ${BATCH_SLOT_COLUMNS}
        slot_enrolled_users_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`

const GET_INSTRUCTOR_DATA_BY_PROJECT_ID = `
  query ($projectId: bigint) {
    courses_instructor (
      where: {
        _or: [
            {
            batches: {
                  project_id: { _eq: $projectId }
            }
          },
          {
            batch_slots: {
              batch: {
                project_id: { _eq: $projectId }
              }
            }
          }
        ]
      }
    ) {
      id
      email
      name
      idp_user_name
    }
  }
`

const GET_INSTRUCTORS_BY_BATCH_ID = `
  query ($batchId: Int) {
    courses_instructor (
      where: {
        _or: [
          {
            batches: {
              id: { _eq: $batchId }
            }
          },
          {
            batch_slots: {
              batch: {
                id: { _eq: $batchId }
              }
            }
          }
        ]
      }
    ) {
      id
      email
      name
      idp_user_name
    }
  }
`

const GET_USER_LOCATION_DATA_BY_INSTRCTOR_ID = `
  query ($instructorIds: [bigint!]) {
    courses_user_location (
      where: {
        user_id: { _in: $instructorIds }
      },
      order_by: [{ user_id: desc }, { created_at: desc }]
      distinct_on: user_id
    ) {
      id
      location_latitude
      location_longitude
    }
  }
`

module.exports = {
  COURSE_COLUMNS,
  COURSE_CATEGORY_COLUMNS,
  FETCH_ALL_COURSES_QUERY,
  FETCH_ALL_PARTNER_COURSES_QUERY,
  GET_CERTIFICATION_LOGO,
  GET_ALL_COURSES_ENROLLED_USERS,
  GET_COURSE_ENROLLED_USERS,
  GET_DEMO_BATCHES,
  GET_DEMO_BATCHES_BY_COURSE_ID,
  GET_COURSE_MODULES,
  GET_MOODLE_COURSE_ENROLLED_USERS,
  GET_INSTRUCTOR_DATA_BY_PROJECT_ID,
  GET_USER_LOCATION_DATA_BY_INSTRCTOR_ID,
  GET_INSTRUCTORS_BY_BATCH_ID,
}
