const config = require("../../config")
const {
  FETCH_ALL_COURSES_QUERY,
  FETCH_ALL_PARTNER_COURSES_QUERY,
  GET_ALL_COURSES_ENROLLED_USERS,
  GET_COURSE_ENROLLED_USERS,
  GET_MOODLE_COURSE_ENROLLED_USERS,
  GET_DEMO_BATCHES_BY_COURSE_ID,
  GET_DEMO_BATCHES,
  GET_COURSE_MODULES,
  GET_INSTRUCTOR_DATA_BY_PROJECT_ID,
  GET_USER_LOCATION_DATA_BY_INSTRCTOR_ID,
  GET_INSTRUCTORS_BY_BATCH_ID,
} = require("../graphql/course")
const { toIndianNumber } = require("../utils/object")
const { makeGraphRequest, makeGetRequest } = require("../utils/request")
const _ = require("lodash")
const { throw_error } = require("../utils/error")
const {
  courseFormatter,
} = require("skillstrainer-resource-library/utils/course")
const { s3BaseUrl } = config

class CourseService {
  cachedCourses

  fecthInstructor = async projectId => {
    try {
      const instructorData = await makeGraphRequest(
        GET_INSTRUCTOR_DATA_BY_PROJECT_ID,
        {
          projectId,
        }
      )
      return instructorData
    } catch (error) {
      console.error("Error g instructor data:", error)
      throw error
    }
  }

  fetchInstructorByBatchId(batchId) {
    return makeGraphRequest(GET_INSTRUCTORS_BY_BATCH_ID, { batchId }).then(
      data => data.courses_instructor
    )
  }

  fetchUserLocation = async instructorIds => {
    try {
      const userLocation = await makeGraphRequest(
        GET_USER_LOCATION_DATA_BY_INSTRCTOR_ID,
        { instructorIds }
      )
      return userLocation
    } catch (error) {
      console.log("Error Fetching User Location data:", error)
      throw error
    }
  }

  fetchAllCourses = async partner_id => {
    if (this.cachedCourses) {
      return this.cachedCourses
    }

    let [hasuraCourses, coursesDemoBatchAvailability] = await Promise.all([
      makeGraphRequest(
        ...(!partner_id
          ? [FETCH_ALL_COURSES_QUERY]
          : [FETCH_ALL_PARTNER_COURSES_QUERY, { partner_id: partner_id }])
      ).then(res => {
        return partner_id
          ? res.courses_partner_revenue_split_directives
          : res.courses_course
      }),
      this.getCoursesDemoBatchSlotsAvailability(),
    ]).catch(err => {
      console.log(err)
      throw_error()
    })

    //restructuring partner course data
    if (partner_id) {
      hasuraCourses = await hasuraCourses.map(course => course.course)
    }

    await Promise.all(
      hasuraCourses.reduce((acc, course) => {
        // get enrolled students
        acc.push(
          this.getCourseEnrolledUsers({
            isMoodleCourse: course.is_moodle_course,
            moodleCourseId: course.moodle_course_id,
            id: course.id,
            courseDiscontinuedFromMoodleAt: course.discontinued_from_moodle_at,
          })
            .then(count => (course.students_enrolled = count))
            .catch(console.error)
        )

        // get course modules
        acc.push(
          this.getCourseModules(course.moodle_course_id).then(
            modules => modules && (course.modules = modules)
          )
        )

        // get course demo availability
        if (coursesDemoBatchAvailability[course.id])
          course.isDemoAvailable = true

        return acc
      }, [])
    ).catch(console.error)

    // Formatting course list
    hasuraCourses.sort((a, b) => b.students_enrolled - a.students_enrolled)
    hasuraCourses = hasuraCourses.map(e => ({
      ...courseFormatter({
        ...e,
        students_enrolled: toIndianNumber(e.students_enrolled || 0),
        partners:
          e.course_partners &&
          e.course_partners.map(cp => {
            cp.partner.logo = s3BaseUrl + "/" + cp.partner.logo
            return cp.partner
          }),
        course_partners: undefined,
        video_url: e.video_url
          ? `${config.s3BaseUrl}/${e.video_url}`
          : undefined,
        certificate_image_url: e.certificate_image_url
          ? `${config.s3BaseUrl}/${e.certificate_image_url}`
          : undefined,
      }),
      isDemoAvailable: e.isDemoAvailable,
    }))

    this.cachedCourses = hasuraCourses

    return hasuraCourses
  }

  getCoursesEnrolledUsers = () =>
    makeGraphRequest(GET_ALL_COURSES_ENROLLED_USERS)
      .then(data =>
        data.courses_moodle_courses__user_stats.reduce(
          (coursesUsers, courseUser) => {
            courseUser.students_enrolled = Number(courseUser.students_enrolled)
            const existingCourse = coursesUsers.find(
              c => c.moodle_course_id === courseUser.moodle_course_id
            )
            if (existingCourse) {
              existingCourse.total_users += courseUser.total_users
            } else coursesUsers.push(courseUser)

            return coursesUsers
          },
          []
        )
      )
      .catch(err => {
        if (config.env !== "production") {
          console.error(err)
          return []
        } else throw err
      })

  getCourseEnrolledUsers = async ({
    isMoodleCourse,
    moodleCourseId,
    id,
    courseDiscontinuedFromMoodleAt,
  }) => {
    let total = 0

    /*
      discontinuedFromMoodleAt: doesn't exist
      isMoodleCourse: false
      => it was never on moodle
        - get all course enrollment count

      discontinuedFromMoodleAt: exists
      isMoodleCourse: false
      => it has been discontinued on moodle
        - get moodle student count and course enrollment count after discontinuedFromMoodleAt

      discontinuedFromMoodleAt: doesn't exist
      isMoodleCourse: true
      => it is still on moodle
        - get moodle student count
    */

    const wasNeverOnMoodle = !courseDiscontinuedFromMoodleAt && !isMoodleCourse,
      isDiscontinuedFromMoodle =
        courseDiscontinuedFromMoodleAt && !isMoodleCourse,
      isOnMoodle = isMoodleCourse

    // If moodle course exists, get moodle enrolled users data
    if ((isDiscontinuedFromMoodle || isOnMoodle) && moodleCourseId)
      total += await makeGraphRequest(GET_MOODLE_COURSE_ENROLLED_USERS, {
        moodleCourseId,
      })
        .then(data =>
          data.courses_moodle_courses__user_stats.reduce(
            (total, cur) => total + cur.total_users,
            0
          )
        )
        .catch(err => {
          if (config.env === "production") throw err
          return 0
        })

    if (wasNeverOnMoodle || isDiscontinuedFromMoodle)
      total += await makeGraphRequest(GET_COURSE_ENROLLED_USERS, {
        courseId: id,
        courseDiscontinuedFromMoodleAt: wasNeverOnMoodle
          ? "1990-01-01T00:00:00Z"
          : courseDiscontinuedFromMoodleAt,
      }).then(
        data => data.courses_user_course_enrolment_aggregate.aggregate.count
      )

    return total
  }

  getCourseModules = moodleCourseId => {
    // return Promise.resolve([])

    // Todo: get course modules from moodle if the course is a moodle course, else get them from hasura
    return makeGetRequest(
      `https://lms.skillstrainer.in/moodle/webservice/rest/server.php?wstoken=f9d082318e7bf83102acc934d8db4c02&wsfunction=core_course_get_contents&moodlewsrestformat=json&courseid=${moodleCourseId}`,
      {},
      ""
    )
      .then(data => data)
      .catch(err => console.log(err.response.data))
  }

  getCoursesDemoBatchSlotsAvailability = () => {
    return makeGraphRequest(GET_DEMO_BATCHES).then(data => {
      const batches = data.courses_course_batches
      const courses = {}

      for (const batch of batches) {
        const availableBatchSlots = batch.batch_slots.filter(
          bs =>
            bs.slot_enrolled_users_aggregate.aggregate.count <
            batch.max_learners
        )

        if (availableBatchSlots.length) courses[batch.course_id] = true
      }

      return courses
    })
  }
  getCourseModulesForNonMoodleCourse = courseId => {
    return makeGraphRequest(GET_COURSE_MODULES, { courseId }).then(res => {
      return res.courses_course_section
    })
  }

  getCourseDemoBatchSlotsAvailability = courseId => {
    return makeGraphRequest(GET_DEMO_BATCHES_BY_COURSE_ID, { courseId }).then(
      data => {
        const batches = data.courses_course_batches
        const availableBatchSlots = batches.reduce((all_batch_slots, batch) => {
          return all_batch_slots.concat(
            batch.batch_slots.filter(
              bs =>
                bs.slot_enrolled_users_aggregate.aggregate.count <
                batch.max_learners
            )
          )
        }, [])
        return !!availableBatchSlots.length
      }
    )
  }
}

const courseSvc = new CourseService()

module.exports = courseSvc
