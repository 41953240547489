// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-dynamic-pages-about-pages-js": () => import("./../../../src/dynamic-pages/about-pages.js" /* webpackChunkName: "component---src-dynamic-pages-about-pages-js" */),
  "component---src-dynamic-pages-blog-post-js": () => import("./../../../src/dynamic-pages/blog-post.js" /* webpackChunkName: "component---src-dynamic-pages-blog-post-js" */),
  "component---src-dynamic-pages-blogs-index-js": () => import("./../../../src/dynamic-pages/blogs-index.js" /* webpackChunkName: "component---src-dynamic-pages-blogs-index-js" */),
  "component---src-dynamic-pages-category-js": () => import("./../../../src/dynamic-pages/category.js" /* webpackChunkName: "component---src-dynamic-pages-category-js" */),
  "component---src-dynamic-pages-contactus-js": () => import("./../../../src/dynamic-pages/contactus.js" /* webpackChunkName: "component---src-dynamic-pages-contactus-js" */),
  "component---src-dynamic-pages-course-detail-js": () => import("./../../../src/dynamic-pages/course-detail.js" /* webpackChunkName: "component---src-dynamic-pages-course-detail-js" */),
  "component---src-dynamic-pages-home-pages-js": () => import("./../../../src/dynamic-pages/home-pages.js" /* webpackChunkName: "component---src-dynamic-pages-home-pages-js" */),
  "component---src-dynamic-pages-privacy-policy-pages-js": () => import("./../../../src/dynamic-pages/privacy-policy-pages.js" /* webpackChunkName: "component---src-dynamic-pages-privacy-policy-pages-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[jwt]-login-jwt-access-token-js": () => import("./../../../src/pages/jwtLogin/[jwt]/[accessToken].js" /* webpackChunkName: "component---src-pages-[jwt]-login-jwt-access-token-js" */),
  "component---src-pages-about-2-js": () => import("./../../../src/pages/about-2.js" /* webpackChunkName: "component---src-pages-about-2-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/SignUp.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-trainers-js": () => import("./../../../src/pages/Trainers.js" /* webpackChunkName: "component---src-pages-trainers-js" */)
}

