const {
  backendHost,
  graphHost,
  // hasuraSecret
} = require("../../config")
const axios = require("axios")
const { partialObject } = require("./object")
const _ = require("lodash")
const { window } = require("browser-monads")
const { webappHost } = require("../../config")

// Requests for Strapi CMS
let token = "",
  accessToken = ""

const getToken = () => token
const getAccessToken = () => accessToken

const setToken = _token => {
  token = _token
  if (typeof localStorage !== "undefined") localStorage.setItem("token", token)
}
const setAccessToken = _token => {
  accessToken = _token
  if (typeof localStorage !== "undefined")
    localStorage.setItem("accessToken", accessToken)
}

const clearToken = () => {
  token = ""
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem("token")
    localStorage.removeItem("accessToken")
  }
}

const validatePythonToken = async token => {
  return (
    token &&
    (await makeGraphRequest(
      `query { courses_admin_users { id }}`,
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    )
      .then(() => true)
      .catch(console.error))
  )
}

const getTokenData = token => {
  try {
    const json = JSON.parse(window.atob(token.split(".")[1]))
    return json
  } catch (err) {
    return false
  }
}

const handleRequestError = err => {
  console.log("Request error", err)
  if ([401, 403].includes(_.get(err, "response.data.statusCode"))) {
    console.log("The status code was 403")
    // If the token has expired, clear user and token in auth context
    // if (setTokenAndUser) setTokenAndUser(false, false)
  }
}

const makeGetRequest = (endPoint, additionalHeaders = {}, base = backendHost) =>
  axios({
    method: "get",
    url: base + endPoint,
    headers: {
      ...additionalHeaders,
    },
  })
    .then(({ data }) => data)
    .catch(handleRequestError)

const makePostRequest = (
  endPoint,
  payload,
  additionalHeaders = {},
  base = backendHost
) =>
  axios({
    method: "post",
    url: base + endPoint,
    headers: {
      ...additionalHeaders,
    },
    data: payload,
  })
    .then(res => {
      return res.data
    })
    .catch(handleRequestError)

const makePutRequest = (
  endPoint,
  payload,
  additionalHeaders = {},
  base = backendHost
) =>
  axios({
    method: "put",
    url: base + endPoint,
    headers: {
      ...additionalHeaders,
    },
    data: payload,
  })
    .then(({ data }) => data)
    .catch(handleRequestError)

// Requests for Hasura
const makeGraphRequest = (
  query,
  variables = {},
  additionalHeaders = {},
  base = graphHost
) => {
  return makePostRequest(
    "",
    { query, variables },
    {
      ...partialObject(token, {
        Authorization: `Bearer ${token}`,
      }),
      ...additionalHeaders,
    },
    base
  ).then(res => {
    if (res.errors) throw res.errors
    else return _.cloneDeep(res.data)
  })
}

const redirectProdAdminPanelToLocalEditableMarketingWebsite = () => {
  let cookies = document.cookie.split(";").map(e => e.trim())
  let jwtToken = cookies.find(c => c.split("=")[0] == "sa_jwt_token")
  if (jwtToken) {
    jwtToken = jwtToken.split("=")[1]
    let accessToken = cookies
      .find(c => c.split("=")[0] == "sa_access_token")
      .split("=")[1]
    window.location.href = `http://localhost:4000/jwtLogin/${jwtToken}/${accessToken}`
  } else return "No tokens found"
}

const WEBAPP_ROUTE_ACTIONS = {
  apply_coupon: "apply-coupon",
  book_demo_course: "book-course-demo",
  join_batch: "join-batch",
  join_demo_session: "join-demo-session",
  purchase_course: "purchase-course",
  apply_for_scholarship: "apply-for-scholarship",
}

const getWebappRouteActionURL = (routeAction, params) => {
  const url = `${webappHost}/?action=${routeAction}&${Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join("&")}`
  console.log(url)

  return url
}

module.exports = {
  token,
  getToken,
  getAccessToken,
  setToken,
  setAccessToken,
  clearToken,
  getTokenData,
  validatePythonToken,
  handleRequestError,
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  makeGraphRequest,

  WEBAPP_ROUTE_ACTIONS,
  getWebappRouteActionURL,
}
